import { breakpointsTailwind } from '@vueuse/core'

const useAppBreakpoints = () => {
  const { isMobile } = useDevice()
  const { greater } = useBreakpoints(breakpointsTailwind)

  return {
    isMobile,
    isLargeScreen: greater('2xl'),
  }
}

export default useAppBreakpoints
